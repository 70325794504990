import React, { useRef, useState, useEffect } from "react";
import { getOrgbyId } from "redux/actions/organization";
import { getBranchById } from "redux/actions/branch";
import { getFavorates } from "redux/actions/favorates";

import { RootState } from "redux/reducers";
import { useDispatch, useSelector } from "react-redux";
import { logOutUser } from "redux/actions/user";
import { getBranchacademicbatchs } from "redux/actions/branchacademicbatch";
import Navbar from "react-bootstrap/Navbar";
import { setAlert } from "../../../redux/actions/alert";

import DefaultProfileImage from "components/shared/defaultProfileLogo";
import { getProfiles } from "redux/actions/profile";

import { getlogo } from "redux/actions/downloads";
import { getAcademicbatchs } from "redux/actions/academicbatch";
import { Link, useLocation, useNavigate } from "react-router-dom";
import {
  Button,
  Container,
  Dropdown,
  FormControl,
  InputGroup,
  Nav,
  Overlay,
  Popover,
} from "react-bootstrap";

import Subheader from "pages/Home/HeaderMain/Subheader";
import Breadcrumb from "./Breadcrumb";
import SearchRoutes from "./SearchRoutes";
import { getNotifications, updateReadNotificationCount } from "redux/actions/notifications";
import dateFormatter from "utils/dateFormatter";
import { getRolesById } from "redux/actions/role";
import Tabs from 'react-bootstrap/Tabs';
import Tab from 'react-bootstrap/Tab';
import * as notification from "../../../services/notifications"
import './headerMain.css'
import InitialSetupBanner from "components/shared/InitialSetup/InitialSetupBanner";
import HeaderRoles from './Roles'
import AcademicYearSelector from "./AcademicYearSelector";

let descriptionData = {
  organization: "Manage and assign roles the user in your organization.",
  finance: "Keep the money flow in your hand in with this feature.",
  admissions:"Admit new students and never loose track of followups.",
  hr: "Manage your staff and keep track of their activities.",
  communications: "Send SMS, create events & manage calender in communications.",
  Attendance: "Track the attendance of staff as well as the students in the organization.",
  student_info: "Keep track of all the activities of the student.",
  certificates: "Find and download student certificates.",
  academics: "Manage the academic class, time table and more.",
  expense_management : "Manage all your expenses without any hassle.",
  exams : "Keep an eye on students academic details.",
  transportmanagement : "All the information about transport in one place.",
  inventory : "Manage your stocks in inventory with ease.",
  visitor_management: "Track the people going in and out of your campus..",
  concerns_feedbacks: 'Manage concerns and Feedbacks',
  hr_pro: 'Manage your Payroll',
  hostel_management: 'Track all your hostel record in one place.',
  social_post: "Manage your social media posts",
  online_class: "Create online class for all the classes",
  assessments: "Create Assessments & Question Bank to student for all Classes",
  lesson_delivery: "Keep track of student lessons and plan classes for teachers"
  // default: "This is a default module.",
};

// Initial Setup
const endpoints = [
  "/modules/expense_management",
  "/modules/hr",
  "/modules/finance",
  "/modules/communications",
  "/modules/certificates",
  "/modules/admissions",
  "/modules/inventory",
  "/modules/organization"
];

const Header: React.FC = (): JSX.Element => {
  let role = localStorage.getItem("role");
  let guestRole = localStorage.getItem("guestRoleId");
  let branchId = localStorage.getItem("branchId");
  let userId = localStorage.getItem("userId");
  let orgId = localStorage.getItem("orgId");

  let use_role_level: any = localStorage.getItem("use_role_level");
  if (role) {
    role = JSON.parse(role);
  }

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const pathLocation = useLocation();

  const ref = useRef(null);

  const [currentYear, setCurrentYear] = React.useState<string>("");
  const [show, setShow] = useState<boolean>(false);
  const [target, setTarget] = useState(null);
  const [subModules, setSubModules] = useState([]);
  const [isHovered, setIsHovered] = useState("");
  const [Logo, setLogo] = React.useState<any>();

  const [enableSearchBox, setEnableSearchBox] = React.useState(false);
  const [searchText, setSearchText] = React.useState("");
  const [event, setEvent] = useState('All')
  const [displayBanner, setDisplayBanner] = useState<boolean>(false);

  const { favorates } = useSelector((state: any) => state.Favorates);
  const { notifications, count : notificationCount, unreadCount:unReadCount } = useSelector(
    (state: any) => state.notificationsReducer);  
  
  let [daywise_notifications,setDaywiseNotifiaction] = useState([])

  const _user: any = useSelector((state: RootState) => state.user);
  
  const { loading: profileLoading, profile: profileData } = useSelector(
    (state: RootState) => state.profile
  );
  const [imageSrc, setImageSrc] = React.useState<string>("");


  const { loading: organizationLoading, organization } = useSelector(
    (state: RootState) => state.organization
  );
  const { loading: branchLoading, branche } = useSelector(
    (state: RootState) => state.branch
  );

  const { initialSetupBannerShow: initialSetupBannerShowData } = useSelector(
    (state: RootState) => state.initialSetupReducer
);

  const { loading: roleLoading, roles } = useSelector((state: RootState) => state.role)
  const [guestRoleName, setGuestRoleName] = useState("");

  //  NAV
 const[showing,setShowing] = useState(false);
 const dropdownRef = useRef(null);
 const searchRef = useRef(null);
 const modulesMenuRef = useRef(null);

 // API CALLS
 const updateReadNotification = async (body) => {
   try {
     const res = await notification.updateReadNotification(body);

     if (res.status < 400) {
       dispatch(
         getNotifications({
           userId,
           skip: 0,
           limit: 15,
         })
       );
     } else {
       const message = res?.response?.data?.message
         ? res?.response?.data?.message
         : "Something went wrong when updating the notification !";
       dispatch<any>(
         setAlert({
           msg: message,
           status: res?.status,
           alertType: "danger",
         })
       );
     }
   } catch {
     const message = "Something went wrong when updating the notification !";
     dispatch<any>(
       setAlert({
         msg: message,
         status: 400,
         alertType: "danger",
       })
     );
   }
 };

 const updateAllReadNotification = async (body) => {
   try {
     const res = await notification.updateAllReadNotification(body);

     if (res.status < 400) {
       dispatch(getNotifications({ userId, skip: 0, limit: 15 }));
     } else {
       const message = res?.response?.data?.message
         ? res?.response?.data?.message
         : "Something went wrong when updating the notification !";
       dispatch<any>(
         setAlert({
           msg: message,
           status: res?.status,
           alertType: "danger",
         })
       );
     }
   } catch {
     const message = "Something went wrong when updating the notification !";
     dispatch<any>(
       setAlert({
         msg: message,
         status: 400,
         alertType: "danger",
       })
     );
   }
 };

//  EFFECTS

 useEffect(() => {
   if (!profileLoading) {
     if (profileData?.image_path) {
       const img: any = dispatch(getlogo(profileData?.image_path));
       img.then((data: any) => setImageSrc(data));
     }
   }
 }, [profileData]);

 useEffect(() => {
   if (userId) {
     dispatch(getProfiles(userId));
     dispatch(getFavorates({ userId }));
     dispatch(getNotifications({ userId, skip: 0, limit: 15 }));
   }
 }, [userId]);

 useEffect(() => {
   if (guestRole && orgId) {
     dispatch(getRolesById({ orgId }));
   }
 }, [guestRole, orgId]);

 useEffect(() => {
   if (!roleLoading) {
     if (guestRole && roles) {
       let guestRoleName = "";
       for (let item of roles.role) {
         if (item._id === guestRole) {
           guestRoleName = item?.name || "";
           break;
         }
       }
       setGuestRoleName(guestRoleName);
     }
   }
 }, [roles, guestRole]);

 useEffect(() => {
   if (showing && notificationCount !== 0) {
     dispatch(updateReadNotificationCount({ userId: userId }));
   }
 }, [showing, notificationCount]);

 useEffect(() => {
   if (notifications) {
     let totalCount = 0;
     const groupedNotifications = Object.values(
       notifications.reduce((result, notification) => {
         const options: any = {
           year: "numeric",
           month: "long",
           day: "2-digit",
         };
         const date = new Date(notification.createdAt).toLocaleDateString(
           "en-GB",
           options
         );

         if (!result[date]) {
          // , unreadCount: 0
           result[date] = { date, notifications: [] };
         }

         if (!notification.isRead) {
          //  result[date].unreadCount++; // Increment unread count if isRead is false
           totalCount++; // Increment total count of unread notifications
         }

         result[date].notifications.push(notification);
         return result;
       }, {})
     );
     setDaywiseNotifiaction(groupedNotifications);
   }
 }, [notifications]);

 useEffect(() => {
   if (use_role_level === "organization") {
     const extractedEndpoint = pathLocation.pathname;
     setDisplayBanner(
       endpoints.includes(extractedEndpoint) && initialSetupBannerShowData
     );
   }
 }, [use_role_level, pathLocation.pathname, initialSetupBannerShowData]);

 useEffect(() => {
   if (use_role_level == "organization" && !guestRole) {
     dispatch(getOrgbyId(orgId));
     dispatch(getAcademicbatchs({ orgId: orgId }, "", true));
   } else if (
     use_role_level == "branch" ||
     (use_role_level == "branch" && guestRole)
   ) {
     dispatch(getOrgbyId(orgId));
     dispatch(getBranchById(branchId));
     dispatch(getBranchacademicbatchs({ branchId: branchId }, "", true));
   }
 }, [use_role_level]);

useEffect(() => {
  let temp = [];
  if (_user?.permissions[0]?.BMRP) {
    _user?.permissions[0]?.BMRP.map((item) => {
      temp.push(item?.branchmodule?.module?.route_path);
    });
  }

  if (favorates) {
    let temp2 = [];
    favorates.map((item) => {
      if (temp.includes(item?.branchmodule_route_path)) {
        temp2.push(item);
      }
    });
    setFinalFavourites(temp2);
  }
}, [_user, favorates]);

 useEffect(() => {
   let id: any = localStorage.getItem("routeId");
   if (!subModules.length && id) {
     if (!_user.loading && _user.permissions.length > 0) {
       let sModules = _user.permissions[0].BMRP.filter((mainModule: any) => {
         if (
           mainModule.branchmodule.module.type == "submodule" &&
           mainModule.branchmodule.module.parentId == id
         )
           return mainModule;
       });
       sModules.sort(
         (a, b) =>
           a?.branchmodule?.module?.sequenceNumber -
           b?.branchmodule?.module?.sequenceNumber
       );
       setSubModules(sModules);
     }
   }
   setSubModules([{}]);
 }, [_user]);

 useEffect(() => {
   if (!organizationLoading) {
     if (orgId && !branchId) {
       if (organization.logo_path) {
         get_Logo(organization.logo_path);
       } else {
         setLogo("/images/default-org.svg");
       }
     }
   }
 }, [organization]);

 useEffect(() => {
   if (!branchLoading) {
     if (branche.logo_path) {
       get_Logo(branche.logo_path);
     } else {
       setLogo("/images/default-org.svg");
     }
   }
 }, [branche]);

 useEffect(() => {
   const handleClickOutside = (event) => {
     // Close the dropdown if the clicked element is outside the dropdown
     if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
       setShowing(false);
     }
     if (
       modulesMenuRef.current &&
       !modulesMenuRef.current.contains(event.target)
     ) {
       handleClickMenu();
     }
     if (searchRef.current && !searchRef.current.contains(event.target)) {
       setEnableSearchBox(false);
       setSearchText("");
     }
   };

   document.addEventListener("click", handleClickOutside);

   // Remove the event listener when the component unmounts
   return () => {
     document.removeEventListener("click", handleClickOutside);
   };
 }, []);


const get_Logo = async (logo_path: any) => {
  const data: any = await dispatch(getlogo(logo_path));
  if (!data) {
    setLogo("/images/default-org.svg");
  } else {
    setLogo(data);
  }
};

  const logout = () => {
    localStorage.clear();
    dispatch<any>(logOutUser(navigate));
  };

  const backTo = () => {
    localStorage.removeItem("guestRoleId");
    localStorage.removeItem("routeId");

    localStorage.removeItem("branchId");
    localStorage.removeItem("branchAcademicBatchId");

    navigate("/dashboard");
    window.location.reload();
  };

  const shiftRole = () => {
    setSubModules([]);
    localStorage.removeItem("routeId");
    navigate("/shiftrole");
  };
  let [finalFavourites,setFinalFavourites] = useState([]) 


  const timeline = () => {
    setSubModules([]);
    localStorage.removeItem("routeId");
    navigate("/timeline");
  };

  const profile = () => {
    setSubModules([]);
    localStorage.removeItem("routeId");
    navigate("/profile");
  };

  const handleClickMenu = () => {
    setShow(false);
  };

  const handleClick = (event: any) => {
    setShow(!show);
    setTarget(event.target);
  };

  const handleClickTimeline = () => {
    timeline();
  }

  const _updateSubheader = (value: any) => {
    localStorage.setItem("routeId", value._id);
    if (!_user.loading && _user.permissions.length > 0) {
      let sModules = _user.permissions[0].BMRP.filter((mainModule: any) => {
        if (
          mainModule.branchmodule.module.type == "submodule" &&
          mainModule.branchmodule.module.parentId == value._id
        )
          return mainModule;
      });
      sModules.sort((a, b) => a?.branchmodule?.module?.sequenceNumber - b?.branchmodule?.module?.sequenceNumber)
      setSubModules(sModules);
    }
    navigate("modules/" + value.route_path);
    setShow(!show);
  };

  const routeHomepage = () => {
    setSubModules([]);
    localStorage.removeItem("routeId");
    navigate("/dashboard");
  };

  const navigation = (item: any) => {
    setSubModules([{}]);
    navigate("/modules/" + item);
  };


  const setSearchBoxHandler = (e: any) => {
    setSearchText(e.target.value);
  };

  const studentStaffNavigationHandler = (e: any) => {
    if (e.target.innerText.includes("Staff")) {
      navigate("/modules/hr/staff_details", {
        state: { searchParam: searchText},
      });
    } else {
      navigate("/modules/student_info/student_details", {
        state: { searchParam: searchText},
      });
    }
    setSearchText("");
    setEnableSearchBox(false);
  };

  const handleReadNotification = (notification: any) => {
    
    if(!notification.isRead){
      updateReadNotification({notificationId : notification._id , deviceType: "WebApp" });
    }
  }

  function formatTime(timestamp: string): string {
    const currentTime: Date = new Date();
    const time: Date = new Date(timestamp);
  
    const timeDiff: number = (currentTime.getTime() - time.getTime()) / (1000 * 60); // Time difference in minutes
  
    if (timeDiff < 60) {
      // Less than 1 hour
      const minutesAgo: number = Math.round(timeDiff);
      return `${minutesAgo} ${minutesAgo === 1 ? 'min' : 'mins'} ago`;
    } else {
      // 1 hour or more
      const options: Intl.DateTimeFormatOptions = {
        hour12: true,
        hour: 'numeric',
        minute: '2-digit'
      };
      return time.toLocaleString('en-US', options);
    }
  }

  function truncateOrganizationName(
    name: string | undefined,
    address: string | undefined
  ): string {
    const maxLength = 50;
    let truncatedName = name || "";
    if (address) {
      const remainingLength = maxLength - address.length;

      if (truncatedName.length > remainingLength) {
        truncatedName = truncatedName.slice(0, remainingLength - 3) + "...";
      }
    } else {
      truncatedName = truncatedName.slice(0, maxLength);
    }
    return truncatedName;
  }

  const createAllNotificationsDropdown = () => {
    let notificationCountTemp = 0;
    daywise_notifications.forEach((daywise_notification) => {
      notificationCountTemp += daywise_notification.notifications.length;
    });

    if (daywise_notifications?.length == 0 || notificationCountTemp == 0) {
      return (
        <div
          style={{ flex: "1" }}
          className="d-flex flex-column justify-content-center align-items-center py-5 my-5"
        >
          <img
            src="/images/empty-notification-dropdown-image.png"
            alt="image of an empty notifications dropdown"
          ></img>
          <span className="mt-3">No notifications available right now</span>
        </div>
      );
    }
    return (
      <div style={{ marginTop: "0rem" }}>
        {daywise_notifications.map((daywise_notification, index) => {
          const currDate = new Date().toISOString();
          let formattedCurrentDate = dateFormatter(currDate);
          return (
            <>
              <Dropdown.Header
                key={index}
                className="theme-dark-color fs-text fw-bold"
              >
                {daywise_notification.date === formattedCurrentDate
                  ? "Today"
                  : daywise_notification.date}
              </Dropdown.Header>
              {daywise_notification?.notifications.map((item, i) => {
                return (
                  <div
                    key={i}
                    className="cursor-pointer"
                    onClick={() => {
                      handleReadNotification(item);
                    }}
                  >
                    <Dropdown.ItemText
                      key={i + item?.date}
                      as="li"
                      className="d-flex flex-column border-0 fs-text mt-1"
                    >
                      <Link
                        to={
                          item?.routes && item?.routes?.adminWebRoute
                            ? item?.routes?.adminWebRoute
                            : "/modules/notifications"
                        }
                        onClick={() => {
                          setShowing((prev) => !prev);
                        }}
                      >
                        <div className="d-flex gap-3 ">
                          <div className="pt-1">
                            <img
                              src="/images/notification_profile_pic.svg"
                              alt="profile"
                              className=""
                            />
                            {item?.isRead === false && (
                              <svg
                                height="12"
                                width="13"
                                style={{
                                  position: "relative",
                                  top: "0",
                                  left: "-1",
                                  transform: "translate(0%, -260%)",
                                }}
                              >
                                <circle
                                  cx="4.5"
                                  cy="4.5"
                                  r="4.5"
                                  fill="#1C5CE9"
                                />
                              </svg>
                            )}
                          </div>
                          <div className="notifications-text-container">
                            <header className="theme-dark-color mb-1  d-flex align-items-center justify-content-between">
                              <span className="fw-bold fs-small">
                                {!item?.title
                                  ? item?.notificationType
                                      ?.toLowerCase()
                                      ?.charAt(0)
                                      ?.toUpperCase() +
                                    item?.notificationType
                                      ?.toLowerCase()
                                      ?.slice(1)
                                  : item?.title}
                              </span>
                              <span className=" fs-small time-text pe-1">
                                {formatTime(item?.createdAt)}
                              </span>
                            </header>
                            <article className="font-weight-light">
                              <p
                                className="text-xs font-normal  fs-small"
                                style={{ color: "#6A6A6A" }}
                              >
                                {item?.body?.length < 150 ? (
                                  <>{item?.body}</>
                                ) : (
                                  <>{item?.body.slice(0, 150) + "...."}</>
                                )}
                              </p>
                            </article>
                          </div>
                        </div>
                      </Link>
                    </Dropdown.ItemText>
                  </div>
                );
              })}
            </>
          );
        })}
      </div>
    );
  };

  const createViewAll = () => {
    let notificationCountTemp = 0;

    if (event === "All" || event === "UnRead" /* || event === "Read" */) {
      daywise_notifications.forEach((daywise_notification) => {
        daywise_notification.notifications.forEach((notification) => {
          if (
            event === "All" ||
            (event === "UnRead" && !notification.isRead)
            // (event === "Read" && notification.isRead)
          ) {
            notificationCountTemp++;
          }
        });
      });
    }

    if (notificationCountTemp !== 0) {
      return (
        <div className="notification-view-all-container">
          <Link
            to="/modules/notifications"
            onClick={() => {
              setShowing((prev) => !prev);
            }}
            className="text-[#1C5CE9] text-xs font-normal flex items-center cursor-pointer fw-500"
          >
            <span className="fs-small">View All</span>
          </Link>
        </div>
      );
    }
  };

  return (
    <>
    <div className="sticky-top">
      <Navbar className="cnavbar" expand={false}>
        <Container
          fluid
          className="main-container justify-content-center justify-content-md-none heading-container"
        >
          <Nav className="me-md-auto org-banner" style={{maxWidth: "270px"}}>
            <Navbar.Brand onClick={routeHomepage}>
            <div className="d-flex justify-content-center align-items-center ">
              <img src={Logo} className="rounded-circle" />
              { 
                (use_role_level === "organization"
                ? (organization as any)?.displayName ?? (organization as any)?.account_name
                : branche?.displayName ?? branche?.branch_name) &&
                (use_role_level === "organization"
                ? (organization as any)?.displayName ?? (organization as any)?.account_name
                : branche?.displayName ?? branche?.branch_name).length > 24 ?
                
              (<div className="ms-2 d-flex ">
                <span className="text-wrap">
                
                  {truncateOrganizationName(
                    use_role_level === "organization"
                      ? (organization as any)?.displayName ?? (organization as any)?.account_name
                      : branche?.displayName ?? branche?.branch_name,
                    use_role_level === "organization"
                      ? (organization as any)?.displayAddress ?? (organization as any)?.address_one
                      : branche?.displayAddress ?? branche?.address
                  ) }
                  <span style={{fontSize: "12px" , color: "#555555"}} className="ms-1">
                
                  {use_role_level === "organization"
                    ? (organization as any)?.displayAddress ?? (organization as any)?.address_one
                    : branche?.displayAddress ?? branche?.address}
              
                </span>
                  
             
                </span>
                
              </div>) : (
                <div className="ms-2 d-flex flex-column">
                <h6 className="mb-0 fw-bold text-wrap ">
                  {
                    use_role_level === "organization"
                      ? (organization as any)?.displayName ?? (organization as any)?.account_name
                      : branche?.displayName ?? branche?.branch_name
                    }
                </h6>
                <p className="mb-0">
                  {use_role_level === "organization"
                    ? (organization as any)?.displayAddress ?? (organization as any)?.address_one
                    : branche?.displayAddress ?? branche?.address}
                </p>
              </div>
              )}
            </div>


            </Navbar.Brand>
          </Nav>
          <div className="header-controls mt-3 mt-md-2 mt-lg-0 d-flex">
            <div className="w-100 d-flex align-items-center justify-content-end justify-content-md-none flex-wrap">
              <div className="position-relative ms-0 ms-sm-auto ms-md-0">
                <Dropdown
                  show={enableSearchBox}
                  ref={searchRef}
                >
                  <Dropdown.Toggle variant="" id="dropdown-basic" onClick={() => {setEnableSearchBox(true)}}>
                    <InputGroup className="mb-0">
                      <FormControl
                        id="global-search"
                        placeholder="Search Here"
                        aria-label="Search Here"
                        className="theme-input-control-light search-control"
                        onChange={setSearchBoxHandler}
                        value={searchText}
                      />
                    </InputGroup>
                  </Dropdown.Toggle>
                  <Dropdown.Menu className="search-box-dropdown">
                    {/* <Dropdown.ItemText className="mt-1 fs-large py-3 px-0 mx-2 fw-bold border-bottom-gray">
                        Result
                      </Dropdown.ItemText> */}
                    <div className={`w-100 mt-0 border-0 pt-0 top-100 ${use_role_level !== "branch" && "mb-16"}`}>
                      <SearchRoutes
                        searchText={searchText}
                        setSearchBox={setEnableSearchBox}
                        setSearchText={setSearchText}
                      />
                      {use_role_level === "branch" && (
                        <div className="text-center search-box-btns mt-16">
                          <button
                            type="button"
                            className="theme-modal-btn theme-action-btn me-1"
                            onClick={studentStaffNavigationHandler}
                          >
                            Search Student
                          </button>
                          <button
                            type="button"
                            className="theme-modal-btn theme-action-btn ms-3"
                            onClick={studentStaffNavigationHandler}
                          >
                            Search Staff
                          </button>
                        </div>
                      )}
                    </div>
                  </Dropdown.Menu>
                </Dropdown>
              </div>
              <div className="d-flex ms-4 me-auto align-items-center h-dwn-header-container">
                <AcademicYearSelector />
              </div>
              <div className="d-flex mt-2 mt-lg-0 ms-3">
                <div ref={ref} className="ms-2 align-self-center mb-1">
                  <Button
                    ref={modulesMenuRef}
                    onClick={handleClick}
                    className="border-0 bg-transparent p-0 header-icon"
                  >
                    <img src="/images/menu.svg" alt="menu" />
                  </Button>
                  <Overlay
                    show={show}
                    target={target}
                    placement="bottom"
                    container={ref}
                    containerPadding={20}
                  >
                    <Popover id="popover-contained" className="border-0 shadow" >
                      {/* <Popover.Header className="p-0 border-0 bg-transparent">
                        <Nav className="dwnnavbar" as="ul">
                          <Nav.Item as="li" className="text-left">
                            <Nav.Link href="#erp" className="frmed f-28">
                              ERP
                            </Nav.Link>
                          </Nav.Item>
                          <Nav.Item as="li" className="text-left">
                            <Nav.Link
                              eventKey="link-1"
                              href="#lms"
                              className="frmed f-28">
                              LMS
                            </Nav.Link>
                          </Nav.Item>
                        </Nav>
                      </Popover.Header> */}
                      <Popover.Body>
                        <ul className={`list-dropdown-custom p-0 pb-2 ${!(_user.permissions?.length > 0) && "justify-content-center"}`} id="erp">
                          {!_user.loading &&
                            _user.permissions?.length > 0 ?
                            _user.permissions[0].BMRP
                              .sort((a, b) => a?.branchmodule?.module?.sequenceNumber - b?.branchmodule?.module?.sequenceNumber)
                              .map((mainModule: any) => {
                                if (
                                  mainModule?.branchmodule?.module?.type ==
                                  "module"
                                )
                                  return (
                                    <li
                                      key={mainModule.branchmodule._id}
                                      onClick={() =>
                                        _updateSubheader(
                                          mainModule.branchmodule.module
                                        )
                                      }
                                      className={`d-flex align-items-center menu-hover-${mainModule.branchmodule.module.route_path}`}
                                    >
                                      {descriptionData[mainModule.branchmodule.module.route_path] ? 
                                        <img
                                          src={`/images/modules/menu/${mainModule.branchmodule.module.route_path}.svg`}
                                          alt={`${mainModule.branchmodule.module.route_path} module`}
                                        /> : 
                                        <img
                                          src={`/images/modules/menu/organization.svg`}
                                          alt={`default`}
                                        />}
                                      <div className="ms-2">
                                        <p className="fs-header fw-bold mb-0">
                                          {mainModule.branchmodule.alias_name}
                                        </p>
                                        <p className="fs-small mb-0 mt-1 lh-sm web-text3">
                                          {
                                              (descriptionData[mainModule.branchmodule.module.route_path] || descriptionData["default"])
                                          }
                                        </p>
                                      </div>
                                    </li>
                                  );
                                else return null;
                              }) : <div className="text-center mt-2">
                              <img
                                src="/images/empty-notification-dropdown-image.png"
                                alt="image of an empty modules list"
                                width={109}
                                height={109}
                              ></img>
                              <p className="mt-3 fs-text">No permissions to access the modules.</p>
                              </div>}
                        </ul>
                      </Popover.Body>
                    </Popover>
                  </Overlay>
                </div>
                <div
                  ref={ref}
                  style={{marginBlockEnd: "5px"}}
                  className="ms-3 ps-2 align-self-center"
                >
                 
                  <Dropdown className="notification" show={showing} ref={dropdownRef}>
                    <Dropdown.Toggle variant="" id="dropdown-basic" className="header-icon border-0 p-0 btn btn-primary" onClick={() => {
                       dispatch(getNotifications({userId,skip: 0,limit:15}));
                      setShowing(prev => !prev);
                    }}>
                      {notificationCount > 0 ? <div className="position-relative">
                          <img src="/images/bell.svg" alt="notification" />
                          <div className="noti-circle"><span className="pb-2">{notificationCount}</span></div>
                      </div> : 
                        <img src="/images/bell.svg" alt="notification" />
                      }
                    </Dropdown.Toggle>

                    <Dropdown.Menu className="notifications-dropdown-menu shadow flex-column">
                        <Dropdown.ItemText className="notifications-title mt-1 fs-large py-4 fw-bold">
                          <div className="notifi-header">
                          <span className="fs-large fw-bold">Notifications</span>
                          {daywise_notifications.length > 0 ? 
                          (
                            <div
                              className=''
                            >
                              <span 
                                className="fs-small fw-500 cursor-pointer" 
                                style={{color:"#1C5CE9"}}
                                onClick={()=>{
                                  updateAllReadNotification({
                                    userId: userId,
                                    deviceType: "WebApp"
                                  })
                                }}
                              
                              >Mark all as read</span>
                            </div>
                          )
                        : <></>}
                        </div>
                      </Dropdown.ItemText>
                      <div className="notification-tabs-view-all-container">
                        {/* Tabs content */}
                        <div className="tabs-content">
                      <div className="width-100 d-flex flex-column">
                        
                      
                      <Tabs defaultActiveKey="All" id="uncontrolled-tab-example" className="theme-tabs d-flex flex-row theme-notification"
                        
                        onSelect={(k) => {setEvent(k)}}>

                        <Tab
                          eventKey="All"
                          title={
                            <span>
                              All
                              ({unReadCount})
                            </span>
                          }
                          mountOnEnter
                          unmountOnExit 
                          className="mt-3"
                          
                        >
                              {createAllNotificationsDropdown()}
                          </Tab>
                          {/* <Tab eventKey="Read" title={<span>Read</span>} mountOnEnter unmountOnExit className="mt-3" onClick={() => setEvent('Read')}>
                            {createReadNotificationsDropdown(true)}
                          </Tab> */}
                          {/* <Tab eventKey="UnRead" title={
                              <span>Unread

                                {
                                    unReadCount > 0 ? <span 
                                      className="ms-2 fs-small"
                                      style={{
                                        backgroundColor: '#E5EEFF',
                                        borderRadius: '50%',
                                        padding: '4px 7px',
                                        color: '#1C5CE9' 
                                      }}
                                    >{unReadCount}</span> : <></>
                                  }
                              </span> 
                            } mountOnEnter unmountOnExit className="mt-3" onClick={() => setEvent('UnRead')}>
                              {createReadNotificationsDropdown(false)}
                          </Tab> */}
                         
                          
                        </Tabs>
                        </div>
                        </div>
                      </div>
                      { 
                        createViewAll()
                      }
                    </Dropdown.Menu>
                  </Dropdown>
                </div>
                <div
                  style={{marginBlockEnd: "5px"}}
                  className="ms-3 ps-2 me-1 align-self-center"
                >
                  <Button
                    onClick={handleClickTimeline}
                    className="border-0 p-0 header-icon"
                  >
                    <img src="/images/headerTimeline.svg" alt="tineline" />
                  </Button>
                </div>
                <div
                  ref={ref}
                  className="ms-4 align-self-stretch d-flex align-items-center"
                >
                  <Dropdown>
                    <Dropdown.Toggle
                      className="border-0 text-decoration-none"
                      variant="link"
                      id="dropdown-basic"
                    >
                      <div className="ms-2 me-1 d-flex align-items-center">
                        {imageSrc ? (
                          <img
                            src={imageSrc}
                            width="34"
                            height="34"
                            className="rounded-circle"
                          />
                        ) : (
                          <DefaultProfileImage
                            text={{
                              firstName: profileData?.firstName
                                ? profileData?.firstName
                                : "Default",
                              lastName: profileData?.lastName
                                ? profileData?.lastName
                                : " ",
                            }}
                            dimentions={{ width: 34, height: 34 }}
                            shape="rounded"
                          />
                        )}
                        <div className="ms-2 ps-1 mb-0">
                          <p className="text-start theme-dark-color fs-text fw-bold mb-0">{`${profileData?.firstName
                            ? profileData?.firstName
                            : "Default User"
                            } ${profileData?.lastName && profileData?.firstName
                              ? " " + profileData?.lastName
                              : " "
                            }`}</p>
                          <p className="text-start mb-0 theme-secondary-focus-color fw-bold fs-mini">
                            {use_role_level === "organization" ? <div className="d-flex align-items-center gap-1"><img width={17} height={16} src="/images/org-level-user.svg"/> <span>{_user?.roleId?.name}</span></div> : <div className="d-flex align-items-center gap-1">{guestRoleName ? <><img width={17} height={16} src="/images/org-level-user.svg"/> <span>{_user?.roleId?.name}</span> <img src="/images/blue-arrow-right.svg"/> <span>{guestRoleName}</span></> : <span>{_user?.roleId?.name}</span>}</div>}
                          </p>
                        </div>
                        <div className="ms-2 rotate-180">
                          <img src="/images/down-arrow.svg" />
                        </div>
                      </div>
                    </Dropdown.Toggle>

                    <Dropdown.Menu className="options-menu header-profile-menu fs-small">
                      <Dropdown.Item onClick={profile} onMouseEnter={() => setIsHovered("Profile")}
                          onMouseLeave={() => setIsHovered("")}>
                      {isHovered === "Profile" ? <img
                          src="/images/profile-dwn-profile-highlight.svg"
                          className="me-2"
                          width={18}
                          height={18}
                        /> :
                        <img
                          src="/images/profile-dwn-profile.svg"
                          className="me-2"
                          width={18}
                          height={18}
                        />}
                        View Profile
                      </Dropdown.Item>
                      <Dropdown.Item onClick={timeline} onMouseEnter={() => setIsHovered("Timeline")}
                          onMouseLeave={() => setIsHovered("")}>
                       {isHovered === "Timeline" ? <img
                          src="/images/profile-dwn-timeline-highlight.svg"
                          className="me-2"
                          width={18}
                          height={18}
                        /> :  
                        <img
                          src="/images/profile-dwn-timeline.svg"
                          className="me-2"
                          width={18}
                          height={18}
                        />}
                        Timeline
                      </Dropdown.Item>
                      <Dropdown.Item
                        style={{
                          display:
                            _user?.roleId?.roleLevel == "organization" && (organization as any)?.enableShiftRole &&
                              !guestRole
                              ? "block"
                              : "none",
                        }}
                        onClick={shiftRole}
                        onMouseEnter={() => setIsHovered("shiftRole")}
                        onMouseLeave={() => setIsHovered("")}
                      >
                      {isHovered === "shiftRole" ?
                        <img
                          src="/images/profile-dwn-shiftrole-highlight.svg"
                          className="me-2"
                          width={18}
                          height={18}
                        />
                        :
                        <img
                          src="/images/profile-dwn-shiftrole.svg"
                          className="me-2"
                          width={18}
                          height={18}
                        />}
                        Shift Role
                      </Dropdown.Item>
                      <Dropdown.Item
                        style={{ display: (organization as any)?.enableShiftRole && guestRole ? "block" : "none" }}
                        onClick={backTo}
                        onMouseEnter={() => setIsHovered("backTo")}
                        onMouseLeave={() => setIsHovered("")}
                      >
                      {isHovered === "backTo" ?
                        <img
                          src="/images/profile-dwn-shiftrole-highlight.svg"
                          className="me-2"
                          width={18}
                          height={18}
                        /> :
                        <img
                          src="/images/profile-dwn-shiftrole.svg"
                          className="me-2"
                          width={18}
                          height={18}
                        />
                      }
                        Back To Role
                      </Dropdown.Item>
                      <Dropdown.Item
                        onClick={() => {
                          navigate("/favorites");
                        }}
                        className="profile-dwn-end"
                        onMouseEnter={() => setIsHovered("Favourites")}
                        onMouseLeave={() => setIsHovered("")}
                      >
                        {isHovered === "Favourites" ?
                        <img
                          src="/images/profile-dwn-favourites-highlight.svg"
                          className="me-2 pb-1"
                          width={18}
                          height={18}
                        /> :
                        <img
                          src="/images/profile-dwn-favourites.svg"
                          className="me-2 pb-1"
                          width={18}
                          height={18}
                        />}
                        Favourites
                      </Dropdown.Item>
                      <Dropdown.Item onClick={logout} className="mt-2">
                        <img
                          src="/images/profile-dwn-logout.svg"
                          className="me-2"
                          width={18}
                          height={18}
                        />
                        <span style={{color: '#DC1C1C'}}>Logout</span>
                      </Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>
                </div>
              </div>
            </div>
          </div>
        </Container>
      </Navbar>
        <div className="d-flex main-container-full justify-content-between">
          <div className="main-container-content align-items-center flex-wrap">
            {subModules?.length > 0 && (
              <div className="tab-sec sub-header-list">
                <Subheader subModules={subModules} />
              </div>
            )}
            <div className="d-flex align-items-center align-self-md-center align-self-start ms-auto gap-2">
              <HeaderRoles />
              {finalFavourites.length > 0 && (
                <div className="subheader-dropdown ms-0">
                  <div className="d-flex ms-auto">
                    <Dropdown>
                      <Dropdown.Toggle
                        className="border-0 text-decoration-none d-flex align-items-center py-2"
                        variant="link"
                        id="dropdown-basic"
                      >
                        <img
                          className="me-2 ms-2" style={{marginBottom: "2px"}}
                          src="/images/favourite-star-white.svg"
                        />
                        <span className="fs-small fw-bold" style={{color : "#FFF"}}>
                          Favourites
                        </span>
                        <img
                          className="me-2 ms-2 rotate-180"
                          src="/images/down-arrow-white.svg"
                          width={20}
                          height={20}
                        />
                      </Dropdown.Toggle>
                      <Dropdown.Menu className="options-menu">
                        {finalFavourites.map((item: any, key: any) => {
                          return (
                            <Dropdown.Item
                              key={key}
                              className="d-flex align-items-center"
                              onClick={() =>
                                navigation(
                                  item?.module_route_path +
                                  "/" +
                                  item?.branchmodule_route_path
                                )
                              }
                            >
                              <img
                                src={`/images/modules/submoduleslist/${item?.module_route_path}/${item?.branchmodule_route_path}.svg`}
                                className="me-2 pe-1 pb-1"
                                width={26}
                                height={26}
                              />
                              <p className="fs-small mb-0" style={{whiteSpace: "nowrap", overflow: "hidden", textOverflow : "ellipsis"}}>
                                {item?.branchmodule[0]?.alias_name}
                              </p>
                            </Dropdown.Item>
                          );
                        })}
                      </Dropdown.Menu>
                    </Dropdown>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
    </div>
    { displayBanner && <InitialSetupBanner />}
    <div className="main-container d-flex justify-content-end">
      <Breadcrumb />
    </div>
    </>
  );
};

export default React.memo(Header);
